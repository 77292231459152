export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: 'cs',
  locale: 'cs',
  pluralRules: {
    cs(choice: number) {
      if (choice === 0) {
        return 0 // Zero
      }

      if (choice === 1) {
        return 1 // Singular
      }

      if (choice >= 2 && choice <= 4) {
        return 2 // Few
      }

      return 3 // Other
    },
  },
}))
